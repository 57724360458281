import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import jsonp from "jsonp";
import dateValidator from "is-my-date-valid";
import styled from "@emotion/styled";

import {
  Button,
  Box,
  Text,
  TextField,
  SelectField,
  TextArea,
  Flex,
  Heading,
  Icon,
  GradientText
} from "@herb/ui";
import ButtonPurple from "@herb/components/Buttons/ButtonPurple";
import ButtonDark from "@herb/components/Buttons/ButtonDark";
import { gridSpace } from "@herb/utils";
import { track } from "@herb/utils/analytics";
import { countries, allCountries } from "@herb/components/AgeGate/data";

import config from "../config";

export const rules = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[0-9\sx\-\(\)\+]*$/
};

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const Checkbox = ({ label, text, ...props }) => {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <label style={{ cursor: "pointer" }}>
          <input
            type="checkbox"
            {...props}
            checked={field.value}
            onChange={() => {
              if (field.value) {
                form.setFieldValue(props.name, false);
              } else {
                form.setFieldValue(props.name, true);
              }
            }}
            style={{ display: "none" }}
          />
          {props.value}
          {label && (
            <Text color="##767676" fontWeight={600} mb={2}>
              {label}
            </Text>
          )}
          <Flex alignItems="flex-start">
            <Flex
              width={24}
              height={24}
              alignItems="center"
              justifyContent="space-around"
              flexShrink={0}
              mr={2}
              p="2px"
              backgroundColor={field.value ? "#7428EF" : "transparent"}
              css={{
                border: "1px solid",
                borderColor: field.value ? "#7428EF" : "#373737",
                borderRadius: 4
              }}
            >
              {field.value && <Icon icon="check" color="background" />}
            </Flex>
            {text && <Text fontSize={2}>{text}</Text>}
          </Flex>
        </label>
      )}
    </Field>
  );
};

export const MailingListForm = ({
  children,
  onSubmit,
  analytics,
  isHomeCali = false,
  buttonStyle = {},
  formLocation = "",
  callback,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: "",
      FORMLOC: formLocation
      // "form-name": "join-mailing-list",
      // "bot-field": ""
    }}
    validate={values => {
      let errors = {};

      // Validate email
      if (!values.email) {
        errors.email = "Required";
      } else if (!rules.email.test(String(values.email).toLowerCase())) {
        errors.email = "Please enter a valid e-mail address";
      }

      return errors;
    }}
    onSubmit={(values, actions) => {
      onSubmit && onSubmit();
      actions.setSubmitting(true);
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: values.email
        })
      };
      let url =
        "https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=SVQR9F";

      fetch(url, options)
        .then(response => {
          actions.setStatus({
            message: "Thanks for signing up."
          });
          actions.setSubmitting(false);
        })
        .catch(err => console.error(err));
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="join-mailing-list"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        {(status || {}).message ? (
          <Text>{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />
            {formLocation && (
              <Field type="hidden" name="FORMLOC" value={formLocation} />
            )}
            <Box>
              <TextField
                as={Field}
                type="text"
                name="email"
                analytics={{ form: "Join Mailing List", ...analytics }}
                placeholder="Email Address"
                aria-label={config.subscribe.placeholder}
                containerStyles={{
                  paddingRight: "0px"
                }}
                backdropProps={{
                  css: {
                    borderColor: "#CCC",
                    borderRadius: "8px",
                    background: "#fff",
                    border: "none"
                  }
                }}
                css={{
                  borderBottom: "1px solid #000",
                  color: "#000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  fontFamily: "Source Code Pro",
                  "@media only screen and (max-device-width: 800px) and (min-device-width: 640px)": {
                    fontSize: "10px"
                  },
                  "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                    fontSize: "12px"
                  },
                  "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                    fontSize: "10px"
                  }
                }}
                suffix={
                  <ButtonPurple
                    css={{
                      position: "absolute",
                      right: "0",
                      top: "-5px",
                      padding: "8px 24px",
                      backgroundColor: "#3041FF",
                      borderRadius: "0",
                      fontWeight: "300",
                      fontFamily: "Source Code Pro",
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: "20px",
                      "&:hover": {
                        border: "1px solid #fff",
                        background: "#fff",
                        color: "#000",
                        boxShadow: "0 0 10px #000"
                      },
                      "@media only screen and (max-device-width: 800px) and (min-device-width: 724px)": {
                        fontSize: "14px"
                      },
                      "@media only screen and (max-device-width: 725px) and (min-device-width: 640px)": {
                        fontSize: "10px"
                      },
                      "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                        fontSize: "12px"
                      },
                      "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                        fontSize: "10px",
                        height: "32px",
                        padding: "10px 12px",
                        top: "5%"
                      }
                    }}
                    onClick={e => {
                      track("Form Submit", {
                        form: "Join Mailing List",
                        ...analytics
                      });
                    }}
                    fontWeight={500}
                    type="submit"
                    disabled={isSubmitting}
                    aria-label="Submit"
                  >
                    subscribe
                  </ButtonPurple>
                }
              />
              <Text
                mt={2}
                color="error"
                fontSize={1}
                css={{ textAlign: "center" }}
              >
                <ErrorMessage name="email" />
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const ArticleMailingListForm = ({
  children,
  onSubmit,
  analytics,
  isHomeCali = false,
  buttonStyle = {},
  formLocation = "",
  callback,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: "",
      FORMLOC: formLocation
      // "form-name": "join-mailing-list",f
      // "bot-field": ""
    }}
    validate={values => {
      let errors = {};

      // Validate email
      if (!values.email) {
        errors.email = "Required";
      } else if (!rules.email.test(String(values.email).toLowerCase())) {
        errors.email = "Please enter a valid e-mail address";
      }

      return errors;
    }}
    onSubmit={(values, actions) => {
      onSubmit && onSubmit();
      actions.setSubmitting(true);
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: values.email
        })
      };
      let url =
        "https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=WjkfLi";

      fetch(url, options)
        .then(response => {
          actions.setStatus({
            message: "Thanks for signing up."
          });
          actions.setSubmitting(false);
        })
        .catch(err => console.error(err));
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="join-mailing-list"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        {(status || {}).message ? (
          <Text>{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />
            {formLocation && (
              <Field type="hidden" name="FORMLOC" value={formLocation} />
            )}
            <Box>
              <TextField
                as={Field}
                type="text"
                name="email"
                analytics={{ form: "Join Mailing List", ...analytics }}
                placeholder="Email Address"
                aria-label={config.subscribe.placeholder}
                containerStyles={{
                  paddingRight: "0px"
                }}
                backdropProps={{
                  css: {
                    borderColor: "#CCC",
                    borderRadius: "8px",
                    background: "#fff",
                    border: "none"
                  }
                }}
                css={{
                  borderBottom: "1px solid #000",
                  color: "#000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  fontFamily: "Source Code Pro",
                  "@media only screen and (max-device-width: 800px) and (min-device-width: 640px)": {
                    fontSize: "10px"
                  },
                  "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                    fontSize: "12px"
                  },
                  "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                    fontSize: "10px"
                  }
                }}
                suffix={
                  <ButtonPurple
                    css={{
                      position: "absolute",
                      right: "0",
                      top: "-5px",
                      padding: "8px 24px",
                      backgroundColor: "#3041FF",
                      borderRadius: "0",
                      fontWeight: "300",
                      fontFamily: "Source Code Pro",
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: "20px",
                      "&:hover": {
                        border: "1px solid #fff",
                        background: "#fff",
                        color: "#000",
                        boxShadow: "0 0 10px #000"
                      },
                      "@media only screen and (max-device-width: 800px) and (min-device-width: 724px)": {
                        fontSize: "14px"
                      },
                      "@media only screen and (max-device-width: 725px) and (min-device-width: 640px)": {
                        fontSize: "10px"
                      },
                      "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                        fontSize: "12px"
                      },
                      "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                        fontSize: "10px",
                        height: "32px",
                        padding: "10px 12px",
                        top: "5%"
                      }
                    }}
                    onClick={e => {
                      track("Form Submit", {
                        form: "Join Mailing List",
                        ...analytics
                      });
                    }}
                    fontWeight={500}
                    type="submit"
                    disabled={isSubmitting}
                    aria-label="Submit"
                  >
                    Redeem
                  </ButtonPurple>
                }
              />
              <Text
                mt={2}
                color="error"
                fontSize={1}
                css={{ textAlign: "center" }}
              >
                <ErrorMessage name="email" />
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const ILGMPopUp = ({
  children,
  onSubmit,
  analytics,
  isHomeCali = false,
  buttonStyle = {},
  formLocation = "",
  callback,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: "",
      FORMLOC: formLocation
      // "form-name": "join-mailing-list",f
      // "bot-field": ""
    }}
    validate={values => {
      let errors = {};

      // Validate email
      if (!values.email) {
        errors.email = "Required";
      } else if (!rules.email.test(String(values.email).toLowerCase())) {
        errors.email = "Please enter a valid e-mail address";
      }

      return errors;
    }}
    onSubmit={(values, actions) => {
      onSubmit && onSubmit();
      actions.setSubmitting(true);
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: values.email
        })
      };
      let url =
        "https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=SJ47Zm";

      fetch(url, options)
        .then(response => {
          actions.setStatus({
            message: "Thanks for signing up."
          });
          actions.setSubmitting(false);
        })
        .catch(err => console.error(err));
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="join-mailing-list"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        {(status || {}).message ? (
          <Text style={{ textAlign: "center" }}>
            Thank you! Use 10FROMROBERT for $10 of your next Buy 10, Get 10
            order!
          </Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />
            {formLocation && (
              <Field type="hidden" name="FORMLOC" value={formLocation} />
            )}
            <Box>
              <TextField
                as={Field}
                type="text"
                name="email"
                analytics={{ form: "Join Mailing List", ...analytics }}
                placeholder="Email Address"
                aria-label={config.subscribe.placeholder}
                containerStyles={{
                  paddingRight: "0px"
                }}
                backdropProps={{
                  css: {
                    borderColor: "#CCC",
                    borderRadius: "8px",
                    background: "#fff",
                    border: "none"
                  }
                }}
                css={{
                  borderBottom: "1px solid #000",
                  color: "#000",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  fontFamily: "Source Code Pro",
                  "@media only screen and (max-device-width: 800px) and (min-device-width: 640px)": {
                    fontSize: "10px"
                  },
                  "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                    fontSize: "12px"
                  },
                  "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                    fontSize: "10px"
                  }
                }}
                suffix={
                  <ButtonPurple
                    css={{
                      position: "absolute",
                      right: "0",
                      top: "-5px",
                      padding: "8px 24px",
                      backgroundColor: "#3041FF",
                      borderRadius: "0",
                      fontWeight: "300",
                      fontFamily: "Source Code Pro",
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      fontSize: "20px",
                      "&:hover": {
                        border: "1px solid #fff",
                        background: "#fff",
                        color: "#000",
                        boxShadow: "0 0 10px #000"
                      },
                      "@media only screen and (max-device-width: 800px) and (min-device-width: 724px)": {
                        fontSize: "14px"
                      },
                      "@media only screen and (max-device-width: 725px) and (min-device-width: 640px)": {
                        fontSize: "10px"
                      },
                      "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                        fontSize: "12px"
                      },
                      "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                        fontSize: "10px",
                        height: "32px",
                        padding: "10px 12px",
                        top: "5%"
                      }
                    }}
                    fontWeight={500}
                    type="submit"
                    disabled={isSubmitting}
                    aria-label="Submit"
                  >
                    Claim Seed Deal
                  </ButtonPurple>
                }
              />
              <Text
                mt={2}
                color="error"
                fontSize={1}
                css={{ textAlign: "center" }}
              >
                <ErrorMessage name="email" />
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const GrowCourseForm = () => (
  <Formik
    initialValues={{
      email: "",
      fullname: ""
      // "form-name": "growing-mailing-list",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      fullname: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);

      const formValues = {
        EMAIL: values.email,
        FULLNAME: values.fullname
      };

      jsonp(
        `${process.env.GATSBY_MAILCHIMP_GROW_URL}&${encode(formValues)}`,
        { param: "c" },
        function(err, data) {
          actions.setStatus({
            message: "Thanks for signing up!"
          });
          actions.setSubmitting(false);
        }
      );

      track("Form Submit", {
        form: "Join Growing List"
      });
    }}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="growing-mailing-list"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text fontWeight="900">{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Box mb={4}>
              <TextField
                as={Field}
                type="text"
                name="fullname"
                analytics={{ form: "Join Growing List" }}
                placeholder={config.growing.placeholders.name}
                label={config.growing.labels.name}
                labelProps={{ color: "white" }}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="fullname" />
              </Text>
            </Box>

            <Box mb={4}>
              <TextField
                as={Field}
                type="email"
                name="email"
                analytics={{ form: "Join Growing List" }}
                placeholder={config.growing.placeholders.email}
                label={config.growing.labels.email}
                labelProps={{ color: "white" }}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="email" />
              </Text>
            </Box>

            <ButtonPurple type="submit">Sign Up</ButtonPurple>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const GrowCourseFormSimple = () => (
  <Formik
    initialValues={{
      email: ""
      // "form-name": "growing-mailing-list-simple",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);

      const formValues = {
        EMAIL: values.email
      };

      jsonp(
        `${process.env.GATSBY_MAILCHIMP_GROW_URL}&${encode(formValues)}`,
        { param: "c" },
        function(err, data) {
          actions.setStatus({
            message: "Thanks for signing up!"
          });
          actions.setSubmitting(false);
        }
      );

      track("Form Submit", {
        form: "Join Growing List from Article"
      });
    }}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="growing-mailing-list-simple"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text fontWeight="900" color="white" textAlign="center">
            {status.message}
          </Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Box>
              <TextField
                as={Field}
                type="email"
                name="email"
                analytics={{ form: "Join Growing List from Article" }}
                placeholder={config.growing.placeholders.email}
                required
                suffix={
                  <Button
                    fontWeight={500}
                    type="submit"
                    disabled={isSubmitting}
                    aria-label="Submit"
                    icon="pointer-right"
                    css={{
                      background: "#7428EF",
                      position: "relative",
                      height: "44px",
                      borderRadius: "10px",
                      padding: "0px 11px 0px 11px",
                      left: "10px"
                    }}
                  >
                    {" "}
                    Let's Grow!
                  </Button>
                }
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="email" />
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const GiveawayForm = () => (
  <Formik
    initialValues={{
      name: "",
      email: ""
      // "form-name": "giveaway-420",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode(values)
      })
        .then(() => {
          actions.setStatus({
            message: "Thanks for entering!"
          });

          track("Form Submit", {
            form: "Giveaway Form"
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="giveaway-420"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text fontWeight="900">{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="name"
                analytics={{ form: "Giveaway Form" }}
                placeholder="Enter your name"
                label="Name*"
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="name" />
              </Text>
            </Box>

            <Box mb={4}>
              <TextField
                as={Field}
                type="text"
                name="email"
                analytics={{ form: "Giveaway Form" }}
                placeholder="Enter your email"
                label="Email*"
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="email" />
              </Text>
            </Box>

            <ButtonDark
              disabled={isSubmitting}
              type="submit"
              css={{
                fontSize: "24px",
                height: "58px",
                lineHeight: "58px",
                padding: "0 50px"
              }}
            >
              Submit
            </ButtonDark>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const CaliNewsletterForm = ({ callback }) => (
  <Formik
    initialValues={{
      name: "",
      email: ""
      // "form-name": `cities-newsletter-cali`,
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: values.email
        })
      };
      let url = `https://us-central1-herb-platform-v3.cloudfunctions.net/klaviyo?listId=XsSXN2`;

      fetch(url, options)
        .then(response => {
          actions.setStatus({
            message: "Thanks for signing up."
          });
          actions.setSubmitting(false);
          callback && callback();
        })
        .catch(err => console.error(err));
    }}
  >
    {({ isSubmitting }) => (
      <Form
        name={`cities-newsletter-cali`}
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        <React.Fragment>
          <Field type="hidden" name="bot-field" />

          <Box>
            <TextField
              as={Field}
              type="text"
              name="email"
              analytics={{ form: "Cali Newsletter" }}
              placeholder="Enter your email"
              backdropProps={{
                background: "#fff",
                border: "none"
              }}
              css={{
                borderBottom: "1px solid #000",
                color: "#000",

                "&:placeholder": {
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "500",
                  fontFamily: "Source Code Pro"
                },
                "@media only screen and (max-device-width: 800px) and (min-device-width: 640px)": {
                  fontSize: "10px"
                },
                "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                  fontSize: "12px"
                },
                "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                  fontSize: "10px"
                }
              }}
              required
              suffix={
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  aria-label="Submit"
                  css={{
                    position: "absolute",
                    right: "7px",
                    top: "-5px",
                    backgroundColor: "#3041FF",
                    borderRadius: "0",
                    fontWeight: "300",
                    fontFamily: "Source Code Pro",
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    "&:hover": {
                      border: "1px solid #fff",
                      background: "#fff",
                      color: "#000",
                      boxShadow: "0 0 10px #000"
                    },
                    "@media only screen and (max-device-width: 800px) and (min-device-width: 724px)": {
                      fontSize: "14px"
                    },
                    "@media only screen and (max-device-width: 725px) and (min-device-width: 640px)": {
                      fontSize: "10px"
                    },
                    "@media only screen and (max-device-width: 430px) and (min-device-width: 375px)": {
                      fontSize: "12px"
                    },
                    "@media only screen and (max-device-width: 374px) and (min-device-width: 300px)": {
                      fontSize: "10px"
                    }
                  }}
                >
                  submit
                  {/* <Icon
                    icon="pointer-right"
                    color="#ffffff"
                    width="32px"
                    height="32px"
                    css={{
                      position: "relative",
                      display: "inline-block",
                      verticalAlign: "middle",
                      top: "-2px"
                    }}
                  /> */}
                </Button>
              }
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="email" />
            </Text>
          </Box>
        </React.Fragment>
      </Form>
    )}
  </Formik>
);

export const ClaimBrandForm = ({ children, ...rest }) => (
  <Formik
    initialValues={{
      name: "",
      company: "",
      country: "default",
      phone: "",
      email: "",
      website: "",
      comments: ""
      // "form-name": "claim-brand",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Required"),
      country: Yup.mixed().notOneOf(["default"], "Required"),
      company: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required"),
      phone: Yup.string()
        .matches(rules.phone, "Invalid phone number")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode(values)
      })
        .then(() => {
          actions.setStatus({
            message: "Thank you, our team will be in touch shortly!"
          });

          if (window) {
            window.scrollTo(0, 0);
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        // name="claim-brand"
        method="POST"
        // netlify-honeypot="bot-field"
        // data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text textAlign={["left", "center"]}>{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Box mx="auto" width={[1, "350px"]}>
              <Box mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="name"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.name}
                  label={config.claim.labels.name}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="name" />
                </Text>
              </Box>

              <Box mb={4}>
                <SelectField
                  as={Field}
                  component="select"
                  name="country"
                  analytics={{ form: "Claim Brand" }}
                  label={config.claim.labels.country}
                >
                  <option value="default">Select a country</option>
                  {countries.map(country => (
                    <option
                      key={`claim-brand-country-${country.value}`}
                      value={country.label}
                    >
                      {country.label}
                    </option>
                  ))}
                </SelectField>
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="country" />
                </Text>
              </Box>

              <Box mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="company"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.company}
                  label={config.claim.labels.company}
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="company" />
                </Text>
              </Box>

              <Box mb={4}>
                <TextField
                  as={Field}
                  type="tel"
                  name="phone"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.phone}
                  label={config.claim.labels.phone}
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="phone" />
                </Text>
              </Box>

              <Box mb={4}>
                <TextField
                  as={Field}
                  type="email"
                  name="email"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.email}
                  label={config.claim.labels.email}
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="email" />
                </Text>
              </Box>

              <Box mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="website"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.website}
                  label={config.claim.labels.website}
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="website" />
                </Text>
              </Box>

              <Box mb={4}>
                <TextArea
                  as={Field}
                  component="textarea"
                  type="text"
                  name="comments"
                  analytics={{ form: "Claim Brand" }}
                  placeholder={config.claim.placeholders.comments}
                  label={config.claim.labels.comments}
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="comments" />
                </Text>
              </Box>

              <Text textAlign="center">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={e => {
                    track("Form Submit", { form: "Claim Brand" });
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Claim Brand"}
                </Button>
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const AdvertiseForm = ({ children, ...rest }) => (
  <Formik
    initialValues={{
      name: "",
      company: "",
      email: "",
      position: "",
      message: ""
      // "form-name": "advertise",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Required"),
      company: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode(values)
      })
        .then(() => {
          actions.setStatus({
            message: "Thank you, our team will be in touch shortly!"
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="advertise"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text>{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Flex mx={gridSpace.parent} flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="name"
                  analytics={{ form: "Advertise" }}
                  placeholder={config.advertise.placeholders.name}
                  label={config.advertise.labels.name}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="name" />
                </Text>
              </Box>
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="email"
                  name="email"
                  analytics={{ form: "Advertise" }}
                  placeholder={config.advertise.placeholders.email}
                  label={config.advertise.labels.email}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="email" />
                </Text>
              </Box>
            </Flex>

            <Flex mx={gridSpace.parent} flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="company"
                  analytics={{ form: "Advertise" }}
                  placeholder={config.advertise.placeholders.company}
                  label={config.advertise.labels.company}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="company" />
                </Text>
              </Box>
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="position"
                  analytics={{ form: "Advertise" }}
                  placeholder={config.advertise.placeholders.position}
                  label={config.advertise.labels.position}
                />
              </Box>
            </Flex>

            <Box mb={4}>
              <TextArea
                as={Field}
                component="textarea"
                type="text"
                name="message"
                analytics={{ form: "Advertise" }}
                placeholder={config.advertise.placeholders.message}
                label={config.advertise.labels.message}
              />
            </Box>

            <Text textAlign={["center", "", "left"]}>
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={e => {
                  track("Form Submit", { form: "Advertise" });
                }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Text>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const BrandForm = ({ children, ...rest }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        company: "",
        position: "",
        budget: "",
        product: "default",
        message: ""
        // "form-name": "brands",
        // "bot-field": ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        company: Yup.string().required("Required"),
        product: Yup.mixed().notOneOf(["default"], "Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Required")
      })}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: encode(values)
        })
          .then(() => {
            actions.setStatus({
              message: "Thank you, our team will be in touch shortly!"
            });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
      {...rest}
    >
      {({ status, isSubmitting }) => (
        <Form
          name="brands"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          noValidate
        >
          {(status || {}).message ? (
            <Text>{status.message}</Text>
          ) : (
            <React.Fragment>
              <Field type="hidden" name="bot-field" />

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="name"
                  analytics={{ form: "Brand" }}
                  placeholder={config.brand.placeholders.name}
                  label={config.brand.labels.name}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="name" />
                </Text>
              </Box>
              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="email"
                  name="email"
                  analytics={{ form: "Brand" }}
                  placeholder={config.brand.placeholders.email}
                  label={config.brand.labels.email}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="email" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="company"
                  analytics={{ form: "Brand" }}
                  placeholder={config.brand.placeholders.company}
                  label={config.brand.labels.company}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="company" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="position"
                  analytics={{ form: "Brand" }}
                  placeholder={config.brand.placeholders.position}
                  label={config.brand.labels.position}
                />
              </Box>

              <Box width={1} mb={3}>
                <SelectField
                  as={Field}
                  component="select"
                  name="budget"
                  analytics={{ form: "Brand" }}
                  label={config.brand.labels.budget}
                >
                  <option value="default">
                    {config.brand.placeholders.budget}
                  </option>
                  {["$2k", "$5k", "$10k", "$25k +"].map((amount, i) => (
                    <option key={i} value={amount}>
                      {amount}
                    </option>
                  ))}
                </SelectField>
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="budget" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <SelectField
                  as={Field}
                  component="select"
                  name="product"
                  analytics={{ form: "Brand" }}
                  label={config.brand.labels.product}
                >
                  <option value="default">
                    {config.brand.placeholders.product}
                  </option>
                  {[
                    "Display ads",
                    "Content (video, articles, social posts)",
                    "Social growth & amplification",
                    "Product and brand listings",
                    "Custom content",
                    "Herb Pickup"
                  ].map((amount, i) => (
                    <option key={i} value={amount}>
                      {amount}
                    </option>
                  ))}
                </SelectField>
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="product" />
                </Text>
              </Box>

              <Box mb={3}>
                <TextArea
                  as={Field}
                  component="textarea"
                  type="text"
                  name="message"
                  analytics={{ form: "Brand" }}
                  placeholder={config.brand.placeholders.message}
                  label={config.brand.labels.message}
                />
              </Box>

              <Text textAlign={["center", "", "left"]}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={e => {
                    track("Form Submit", { form: "Brand" });
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Text>
            </React.Fragment>
          )}
        </Form>
      )}
    </Formik>
  );
};

export const RetailerForm = ({ children, ...rest }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        company: "",
        city: "",
        position: "",
        message: ""
        // "form-name": "retailers",
        // "bot-field": ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        company: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Required")
      })}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: encode(values)
        })
          .then(() => {
            actions.setStatus({
              message: "Thank you, our team will be in touch shortly!"
            });
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
      {...rest}
    >
      {({ status, isSubmitting }) => (
        <Form
          name="retailers"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          noValidate
        >
          {(status || {}).message ? (
            <Text>{status.message}</Text>
          ) : (
            <React.Fragment>
              <Field type="hidden" name="bot-field" />

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="name"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.name}
                  label={config.retailer.labels.name}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="name" />
                </Text>
              </Box>
              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="email"
                  name="email"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.email}
                  label={config.retailer.labels.email}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="email" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="company"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.company}
                  label={config.retailer.labels.company}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="company" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="city"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.city}
                  label={config.retailer.labels.city}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="city" />
                </Text>
              </Box>

              <Box width={1} mb={3}>
                <TextField
                  as={Field}
                  type="text"
                  name="position"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.position}
                  label={config.retailer.labels.position}
                />
              </Box>

              <Box mb={3}>
                <TextArea
                  as={Field}
                  component="textarea"
                  type="text"
                  name="message"
                  analytics={{ form: "Retailer" }}
                  placeholder={config.retailer.placeholders.message}
                  label={config.retailer.labels.message}
                />
              </Box>

              <Text textAlign={["center", "", "left"]}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={e => {
                    track("Form Submit", { form: "Retailer" });
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Text>
            </React.Fragment>
          )}
        </Form>
      )}
    </Formik>
  );
};

export const HerbHelpsForm = ({ children, ...rest }) => (
  <Formik
    initialValues={{
      name: "",
      company: "",
      email: "",
      position: "",
      message: "",
      budget: ""
      // "form-name": "herb-helps",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Required"),
      company: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode(values)
      })
        .then(() => {
          actions.setStatus({
            message: "Thank you, our team will be in touch shortly!"
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
    {...rest}
  >
    {({ status, isSubmitting }) => (
      <Form
        name="herb-helps"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text>{status.message}</Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Flex mx={gridSpace.parent} flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="name"
                  analytics={{ form: "Herb Helps" }}
                  placeholder={config.advertise.placeholders.name}
                  label={config.advertise.labels.name}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="name" />
                </Text>
              </Box>
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="email"
                  name="email"
                  analytics={{ form: "Herb Helps" }}
                  placeholder={config.advertise.placeholders.email}
                  label={config.advertise.labels.email}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="email" />
                </Text>
              </Box>
            </Flex>

            <Flex mx={gridSpace.parent} flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="company"
                  analytics={{ form: "Herb Helps" }}
                  placeholder={config.advertise.placeholders.company}
                  label={config.advertise.labels.company}
                  required
                />
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="company" />
                </Text>
              </Box>
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <TextField
                  as={Field}
                  type="text"
                  name="position"
                  analytics={{ form: "Herb Helps" }}
                  placeholder={config.advertise.placeholders.position}
                  label={config.advertise.labels.position}
                />
              </Box>
            </Flex>

            <Flex mx={gridSpace.parent} flexWrap="wrap">
              <Box width={[1, 1 / 2]} px={gridSpace.box} mb={4}>
                <SelectField
                  as={Field}
                  component="select"
                  name="budget"
                  analytics={{ form: "Brand" }}
                  label={config.brand.labels.budget}
                >
                  <option value="default">
                    {config.brand.placeholders.budget}
                  </option>
                  {["$2k", "$5k", "$10k", "$25k +"].map((amount, i) => (
                    <option key={i} value={amount}>
                      {amount}
                    </option>
                  ))}
                </SelectField>
                <Text mt={2} color="error" fontSize={1}>
                  <ErrorMessage name="budget" />
                </Text>
              </Box>
            </Flex>

            <Box mb={4}>
              <TextArea
                as={Field}
                component="textarea"
                type="text"
                name="message"
                analytics={{ form: "Herb Helps" }}
                placeholder={config.advertise.placeholders.message}
                label={config.advertise.labels.message}
              />
            </Box>

            <Text textAlign={["center", "", "left"]}>
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={e => {
                  track("Form Submit", { form: "Herb Helps" });
                }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Text>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

export const PricingForm = ({ children, ...rest }) => (
  <Formik
    initialValues={{
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      position: "",
      reason: "",
      notes: "",
      budget: ""
      // "form-name": "pricing-form",
      // "bot-field": ""
    }}
    validationSchema={Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      company: Yup.string().required("Required"),
      position: Yup.string().required("Required"),
      budget: Yup.string().required("Required"),
      reason: Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode(values)
      })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
    {...rest}
  >
    {({
      status,
      handleSubmit,
      setStatus,
      isValid,
      isValidating,
      isSubmitting
    }) => (
      <Form
        name="pricing-form"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        {(status || {}).message ? (
          <Text textAlign="center" mt={3}>
            {status.message}
          </Text>
        ) : (
          <React.Fragment>
            <Field type="hidden" name="bot-field" />

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="firstName"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.firstName}
                label={config.pricing.labels.firstName}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="firstName" />
              </Text>
            </Box>

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="lastName"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.lastName}
                label={config.pricing.labels.lastName}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="lastName" />
              </Text>
            </Box>

            <Box mb={3}>
              <TextField
                as={Field}
                type="email"
                name="email"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.email}
                label={config.pricing.labels.email}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="email" />
              </Text>
            </Box>

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="company"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.company}
                label={config.pricing.labels.company}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="company" />
              </Text>
            </Box>

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="position"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.position}
                label={config.pricing.labels.position}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="position" />
              </Text>
            </Box>

            <Box mb={3}>
              <SelectField
                as={Field}
                component="select"
                name="budget"
                analytics={{ form: "Pricing Form" }}
                label={config.pricing.labels.budget}
                required
              >
                <option value="">{config.pricing.placeholders.budget}</option>
                {[
                  "$0-$14k",
                  "$15k-$50k",
                  "$51k-$100k",
                  "$101k - $200k",
                  "$201k +"
                ].map((amount, i) => (
                  <option key={i} value={amount}>
                    {amount}
                  </option>
                ))}
              </SelectField>
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="budget" />
              </Text>
            </Box>

            <Box mb={3}>
              <TextField
                as={Field}
                type="text"
                name="reason"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.reason}
                label={config.pricing.labels.reason}
                required
              />
              <Text mt={2} color="error" fontSize={1}>
                <ErrorMessage name="reason" />
              </Text>
            </Box>

            <Box mb={5}>
              <TextArea
                as={Field}
                component="textarea"
                type="text"
                name="message"
                analytics={{ form: "Pricing Form" }}
                placeholder={config.pricing.placeholders.message}
                label={config.pricing.labels.message}
              />
            </Box>

            <Flex width={1} justifyContent={["center", "", "left"]}>
              {/* <RocketButton
                isValid={isValid}
                isValidating={isValidating}
                isSubmitting={isSubmitting}
                handleSubmit={handleSubmit}
                setStatus={setStatus}
              /> */}
            </Flex>
          </React.Fragment>
        )}
      </Form>
    )}
  </Formik>
);

const Select = styled.select`
  font-size: 15px;
  max-width: 250px;
  margin-right: 10px;
  width: 100%;
  background-color: #000;
  padding: 12px;
  border: 1px solid #e8e9eb;
  border-radius: 20px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 6px;
  margin-bottom: 15px;
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  text-indent: 1px;
`;

export const MarketingForm = ({ children, ...rest }) => (
  <Formik
    initialValues={{
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      city: "",
      state: "",
      "00N5f00000c17Xu": "",
      "00N5f00000c17Yi": "",
      receiveEmail: false,
      "00N5f00000c3v7a": ""
      // "form-name": "marketing-form"
    }}
    validationSchema={Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      company: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      "00N5f00000c17Yi": Yup.string().required("Required"),
      "00N5f00000c17Xu": Yup.string().required("Required"),
      "00N5f00000c3v7a": Yup.string().required("Required"),
      email: Yup.string()
        .email("Invalid email")
        .required("Required")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      // actions.setStatus({
      //   message:
      //     "This guide is a summary of our key learnings we have collected over the years helping cannabis brands achieve their marketing and revenue goals. We hope you find it useful!"
      // });
      fetch(
        "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: encode(values)
        }
      )
        .catch(error => {})
        .finally(() => {
          actions.setSubmitting(false);
        });
    }}
    {...rest}
  >
    {({
      status,
      handleSubmit,
      setFieldValue,
      setStatus,
      isValid,
      handleChange,
      handleBlur,
      values,
      isValidating,
      isSubmitting
    }) => (
      <form
        method="POST"
        onSubmit={handleSubmit}
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      >
        <input
          type="hidden"
          name="captcha_settings"
          value='{"keyname":"HerbReCaptcha","fallback":"true","orgId":"00D5f000007G9M3","ts":""}'
        />
        <input type="hidden" name="oid" value="00D5f000007G9M3" />
        <input
          type="hidden"
          name="retURL"
          value="https://herb.co/marketing/download/"
        />
        {(status || {}).message ? (
          <Flex
            css={{
              flexDirection: "column",
              alignItems: "flex-start",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <GradientText>Thank you for signing up!</GradientText>
            <Text css={{ marginTop: "48px" }}>{status.message}</Text>

            <ButtonPurple
              as="a"
              href="https://herb-platform-images.s3.amazonaws.com/Herb+Marketing+Guide2022.pdf"
              target="_blank"
              css={{
                marginTop: "48px"
              }}
            >
              Download The Guide
            </ButtonPurple>
          </Flex>
        ) : (
          <React.Fragment>
            <Heading
              css={{
                background:
                  "linear-gradient(to right, #7428EF 0%, #D459FF 105.12%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "38px",
                paddingBottom: "25px",
                "@media screen and (max-width:768px)": {
                  fontSize: "30px"
                }
              }}
            >
              Build Your 2024 Marketing Plan in 5 Seconds
            </Heading>
            {/* First Name */}
            <input
              style={{
                outline: "none",
                "&:placeholder": {
                  color: "#fff"
                },
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                marginTop: "6px",
                marginBottom: "15px",
                color: "#fff"
              }}
              id="first_name"
              placeholder="First Name*"
              maxlength="40"
              name="first_name"
              size="20"
              type="text"
              Required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="first_name" />
            </Text>
            {/* Last Name */}
            <input
              style={{
                outline: "none",
                "&:placeholder": {
                  color: "#fff"
                },
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                marginTop: "6px",
                marginBottom: "15px",
                color: "#fff"
              }}
              placeholder="Last Name*"
              id="last_name"
              maxlength="80"
              name="last_name"
              size="20"
              type="text"
              Required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="last_name" />
            </Text>
            {/* Email */}
            <input
              style={{
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                color: "#fff",
                outline: "none",
                marginTop: "6px",
                marginBottom: "15px"
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              type="text"
              id="email"
              name="email"
              placeholder="Email Address*"
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="email" />
            </Text>
            {/* Company */}
            <input
              style={{
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                color: "#fff",
                outline: "none",
                marginTop: "6px",
                marginBottom: "15px"
              }}
              id="company"
              maxlength="40"
              name="company"
              size="20"
              type="text"
              Required
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Company*"
              value={values.company}
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="company" />
            </Text>
            {/* City */}
            <input
              style={{
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                color: "#fff",
                outline: "none",
                marginTop: "6px",
                marginBottom: "15px"
              }}
              id="city"
              maxlength="40"
              name="city"
              size="20"
              type="text"
              Required
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="City*"
              value={values.city}
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="city" />
            </Text>
            {/* State */}
            <input
              style={{
                fontSize: "15px",
                width: "100%",
                backgroundColor: "#000",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                color: "#fff",
                outline: "none",
                marginTop: "6px",
                marginBottom: "15px"
              }}
              id="state"
              maxlength="20"
              name="state"
              size="20"
              type="text"
              Required
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="State/Province*"
              value={values.state}
            />
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="state" />
            </Text>
            {/* Budget And Category */}
            <Flex
              css={{
                justifyContent: "space-between",
                color: "#fff",
                "@media screen and (max-width:768px)": {
                  flexDirection: "column"
                }
              }}
            >
              <Box width={[1, 1 / 2]}>
                <Select
                  id="00N5f00000c17Xu"
                  name="00N5f00000c17Xu"
                  title="Budget"
                  Required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.budget}
                >
                  <option value="#">Monthly Budget</option>
                  <option value="$0 - 10K per month">$0 - 10K per month</option>
                  <option value="$10K - 25K per month">
                    $10K - 25K per month
                  </option>
                  <option value="$25K+ per month">$25K+ per month</option>
                </Select>
                <Text mt={2} pl={2} color="error" fontSize={1}>
                  <ErrorMessage name="00N5f00000c17Xu" />
                </Text>
              </Box>
              <Box width={[1, 1 / 2]}>
                <Select
                  id="00N5f00000c17Yi"
                  name="00N5f00000c17Yi"
                  title="Category"
                  Required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category}
                >
                  <option value="#">Category</option>
                  <option value="MSO">MSO</option>
                  <option value="Cannabis Brand">Cannabis Brand</option>
                  <option value="Canadian LP">Canadian LP</option>
                  <option value="CBD">CBD</option>
                  <option value="Drinkable">Drinkable</option>
                  <option value="Edible">Edible</option>
                  <option value="Accessory">Accessory</option>
                  <option value="Device">Device</option>
                  <option value="Other">Other</option>
                </Select>
                <Text mt={2} pl={2} color="error" fontSize={1}>
                  <ErrorMessage name="00N5f00000c17Yi" />
                </Text>
              </Box>
            </Flex>
            {/* Comments */}
            <textarea
              style={{
                fontSize: "15px",
                width: "100%",
                outline: "none",
                backgroundColor: "#000",
                color: "#fff",
                padding: "12px",
                border: "1px solid #e8e9eb",
                borderRadius: "20px",
                boxSizing: "border-box",
                marginTop: "6px",
                marginBottom: "15px",
                height: "200px"
              }}
              name="00N5f00000c3v7a"
              type="text"
              wrap="soft"
              id="00N5f00000c3v7a"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              placeholder="How did you hear about Herb?"
            ></textarea>
            <Text mt={2} pl={2} color="error" fontSize={1}>
              <ErrorMessage name="00N5f00000c3v7a" />
            </Text>
            <div className="checkbox-label">
              <input
                style={{
                  fontSize: "15px",
                  color: "#fff",
                  backgroundColor: "#000",
                  padding: "12px",
                  border: "1px solid #e8e9eb",
                  borderRadius: "20px",
                  boxSizing: "border-box",
                  marginTop: "6px",
                  marginBottom: "15px"
                }}
                onChange={e => {
                  setFieldValue("receiveEmail", e.target.checked);
                }}
                value={values.email}
                checked={values.receiveEmail}
                id="receiveEmail"
                type="checkbox"
              />
              <span>I agree to receive marketing updates from Herb</span>
            </div>
            <ButtonPurple
              type="submit"
              css={{
                marginTop: "32px"
              }}
            >
              Let's Get Started
            </ButtonPurple>
          </React.Fragment>
        )}
      </form>
    )}
  </Formik>
);

export const ProfileForm = ({
  name,
  country,
  day,
  month,
  year,
  acceptsMarketing,
  callback,
  children,
  ...rest
}) => (
  <Formik
    initialValues={{
      name: name,
      country: country ? country : "default",
      day: day ? day : "",
      month: month ? month : "",
      year: year ? year : "",
      acceptsMarketing: acceptsMarketing ? true : false
    }}
    validate={values => {
      const validateDate = dateValidator({ format: "YYYY-MM-DD" });
      let errors = {};

      if (values.day || values.month || values.year) {
        if (!validateDate(`${values.year}-${values.month}-${values.day}`)) {
          errors.year =
            "Please user the correct format (DD, MM, YYYY) and ensure your birthday is correct.";
        }
      }

      return errors;
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required("Required"),
      day: Yup.number().typeError("Day must be a number"),
      month: Yup.number().typeError("Month must be a number"),
      year: Yup.number().typeError("Year must be a number")
    })}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);

      if (callback) {
        callback(values);
      }
    }}
    {...rest}
  >
    {({
      status,
      handleSubmit,
      setStatus,
      isValid,
      isValidating,
      isSubmitting
    }) => (
      <Form name="profile-form" method="POST" noValidate>
        <React.Fragment>
          <Field type="hidden" name="bot-field" />

          <Box mb={4}>
            <TextField
              as={Field}
              type="text"
              name="name"
              placeholder=""
              label="Display Name"
              required
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="name" />
            </Text>
          </Box>

          <Box mb={4}>
            <SelectField
              as={Field}
              component="select"
              name="country"
              label="Country"
            >
              <option value="default">Where are you from?</option>
              {allCountries.map(country => (
                <option
                  key={`profile-country-${country.value}`}
                  value={country.value}
                >
                  {country.name}
                </option>
              ))}
            </SelectField>
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="country" />
            </Text>
          </Box>

          <Text
            color="brand.charcoal"
            fontSize={3}
            fontWeight={600}
            mb={2}
            css={{ display: "block" }}
          >
            When is your birthday?
          </Text>

          <Flex mx={-2} mb={4} flexWrap="wrap">
            <Box width={1 / 4} px={2}>
              <TextField as={Field} type="tel" name="day" placeholder="DD" />
            </Box>

            <Box width={1 / 4} px={2}>
              <TextField as={Field} type="tel" name="month" placeholder="MM" />
            </Box>

            <Box width={2 / 4} px={2}>
              <TextField as={Field} type="tel" name="year" placeholder="YYYY" />
            </Box>
            <Box mt={2} px={2}>
              <Text color="error" fontSize={1}>
                <ErrorMessage name="day" />
              </Text>
              <Text color="error" fontSize={1}>
                <ErrorMessage name="month" />
              </Text>
              <Text color="error" fontSize={1}>
                <ErrorMessage name="year" />
              </Text>
            </Box>
          </Flex>

          <Box mb={5}>
            <Checkbox
              name="acceptsMarketing"
              text="I would like to receive cannabis updates delivered to my inbox"
            />
          </Box>

          <Text textAlign="right">
            <ButtonPurple type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save"}
            </ButtonPurple>
          </Text>
        </React.Fragment>
      </Form>
    )}
  </Formik>
);

export const LoginForm = ({
  email,
  password,
  buttonLabel = "Login",
  callback,
  children,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: "",
      password: ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Required"),
      password: Yup.string().required("Required")
    })}
    onSubmit={(values, actions) => {
      if (callback) {
        callback(values);
      }
    }}
    {...rest}
  >
    {({
      status,
      handleSubmit,
      setStatus,
      isValid,
      isValidating,
      isSubmitting
    }) => (
      <Form name="login-form" method="POST" noValidate>
        <React.Fragment>
          <Box mb={2}>
            <TextField
              as={Field}
              type="text"
              name="email"
              placeholder="Email"
              required
              backdropProps={{
                css: {
                  backgroundColor: "#ffffff",
                  border: "1px solid #adaeb6"
                }
              }}
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="email" />
            </Text>
          </Box>

          <Box mb={3}>
            <TextField
              as={Field}
              type="password"
              name="password"
              placeholder="Password"
              required
              backdropProps={{
                css: {
                  backgroundColor: "#ffffff",
                  border: "1px solid #adaeb6"
                }
              }}
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="password" />
            </Text>
          </Box>

          <Text textAlign="center">
            <ButtonPurple
              type="submit"
              css={{ fontSize: "14px", height: "40px", lineHeight: "40px" }}
            >
              {buttonLabel}
            </ButtonPurple>
          </Text>
        </React.Fragment>
      </Form>
    )}
  </Formik>
);

export const SignupForm = ({
  email,
  password,
  buttonLabel = "Signup",
  callback,
  children,
  ...rest
}) => (
  <Formik
    initialValues={{
      email: "",
      password: ""
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .required("Required"),
      password: Yup.string().required("Required")
    })}
    onSubmit={(values, actions) => {
      if (callback) {
        callback(values);
      }
    }}
    {...rest}
  >
    {({
      status,
      handleSubmit,
      setStatus,
      isValid,
      isValidating,
      isSubmitting
    }) => (
      <Form name="signup-form" method="POST" noValidate>
        <React.Fragment>
          <Box mb={2}>
            <TextField
              as={Field}
              type="text"
              name="email"
              placeholder="Email"
              required
              backdropProps={{
                css: {
                  backgroundColor: "#ffffff",
                  border: "1px solid #adaeb6"
                }
              }}
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="email" />
            </Text>
          </Box>

          <Box mb={3}>
            <TextField
              as={Field}
              type="password"
              name="password"
              placeholder="Password"
              required
              backdropProps={{
                css: {
                  backgroundColor: "#ffffff",
                  border: "1px solid #adaeb6"
                }
              }}
            />
            <Text mt={2} color="error" fontSize={1}>
              <ErrorMessage name="password" />
            </Text>
          </Box>

          <Text textAlign="center">
            <ButtonPurple
              type="submit"
              css={{
                fontSize: "14px",
                height: "40px",
                lineHeight: "40px",
                textTransform: "capitalize"
              }}
            >
              {buttonLabel}
            </ButtonPurple>
          </Text>
        </React.Fragment>
      </Form>
    )}
  </Formik>
);
